import { useEffect, useMemo, useState } from 'react';
import combineMedia from '~/utils/combine-media';
import ProductDetails from '~/components/Product/Details';
import PDPWrapper from '~/components/Generic/PDPWrapper';
import { useProductAnalytics } from '~/hooks/utils/customAnalytics';
import { useBadge } from '~/hooks/components/use-badge';

const ProductPage = ({ collection, product, notifications, productCollection }) => {
  // each product have at least one variant (enforced by Contentful model)
  const [variant, setVariant] = useState(product.variants[0]);
  const { trackProductViewed } = useProductAnalytics(product.slug);

  useEffect(() => {
    trackProductViewed({ locationDetail: product.name, collection: collection.slug });
  }, [collection.slug, product.name, trackProductViewed]);

  const { mainImage, images, videos } = variant ?? {};

  const { mediaArrayWithVideos, mediaArrayWithThumbnails } = useMemo(
    () => combineMedia(mainImage, images, videos),
    // assume that data has changed if the mainImage id changed,
    // or the array's first item has changed
    // or the variant has changed
    [images, mainImage, videos]
  );
  const { badges } = product;
  const badge = useBadge({ selectedOption: variant, badges });
  return (
    <PDPWrapper
      selectedVariant={variant}
      product={product}
      variant={variant}
      mediaArrayWithThumbnails={mediaArrayWithThumbnails}
      mediaArrayWithVideos={mediaArrayWithVideos}
      zoomedMediaArrayWithVideos={mediaArrayWithVideos}
      productCollection={productCollection}
      badge={badge}
    >
      <ProductDetails
        notifications={notifications}
        product={product}
        collectionName={collection.name}
        setVariant={setVariant}
        forwardSx={{
          '--horizontal-inset': ['1.9rem', '3.5rem', 0],
          px: 'var(--horizontal-inset)',
          marginBottom: [null, 7, 0],
        }}
      />
    </PDPWrapper>
  );
};

export default ProductPage;
